@import '../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '../node_modules/font-awesome/less/font-awesome';
@import './variables';

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 14px;
  color: #333;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f9fafb;
}

* {
  box-sizing: border-box;
  font-family: 'Inter', 'Open Sans', sans-serif;
}

h2,
p {
  text-align: center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 8px 0;
}

p {
  margin: 0;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  margin-top: 60px;
  height: calc(100vh - 70px);
  background: white;
  padding-top: 60px;

  @media (max-width: @screen-sm) {
    padding: 10px;
    margin: 0;
    height: auto;
  }
}

a,
a:visited,
a:hover {
  color: #1976d2;
  text-decoration: none;
}

a:hover {
  color: #125699;
}

.app-logo {
  margin-right: 10px;
}

.hidden {
  display: none;
}

.form-container {
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
  display: flex;
  flex-flow: row;
  position: relative;

  @media (max-width: @screen-lg) {
    width: auto;
    flex-flow: column;
  }

  .submit-button {
    width: 100%;
  }

  &.full-width {
    width: 564px;

    @media (max-width: @screen-lg) {
      width: auto;
    }

    .form {
      width: 100%;
      padding: 50px 20px;

      .form-logo {
        margin-bottom: 20px;
      }

      .form-group {
        align-items: center;
        position: relative;
        padding-bottom: 10px;
        margin-bottom: 0;

        label {
          align-self: flex-start;
        }

        .alert {
          position: absolute;
          bottom: -15px;
        }
      }

      .submit-button {
        width: 100%;
        text-transform: uppercase;
        padding: 20px;
        margin-top: 20px;
      }
    }
  }

  &.column {
    width: 1128px;
    align-items: center;
    flex-flow: column;
    padding-top: 20px;

    .form {
      width: 50%;
      padding: 20px;

      .form-group.title {
        align-items: flex-start;
      }

      .bottom-text {
        margin-top: 20px;
        margin-bottom: -20px;
      }
    }
  }

  .form-container-inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row;
    position: relative;

    @media (max-width: @screen-sm) {
      flex-flow: column;
    }
  }

  .asset-container {
    width: 50%;
    height: 100%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #edf6ff;
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;

    @media (max-width: @screen-sm) {
      width: 100%;
      position: inherit;
      border-top-right-radius: 0;
      border-bottom-left-radius: 20px;
    }

    .products-title {
      color: #3d4651;
      padding: 20px;
      font-size: 20px;
      font-weight: 500;
      text-align: center;
    }

    .product-tiles {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      width: 100%;
      align-items: center;
      justify-content: center;

      .product-tile {
        width: 200px;
        height: 100px;
        border-radius: 12px;
        background: #ffffff;
        box-shadow: 0px 3px 21px rgb(47 43 70 / 20%);
        display: flex;
        flex-flow: column;
        align-items: center;
        margin: 9px 15px;
        justify-content: center;
        padding: 16px;
        overflow: hidden;
        position: relative;
        cursor: pointer;

        .icon {
          width: 100px;
        }

        .title {
          color: #3d4651;
          text-align: center;
          margin-top: 10px;
        }
      }
    }
  }

  .quote-container {
    width: 50%;
    height: 100%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background: #ffffff;
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: @screen-sm) {
      width: 100%;
      position: inherit;
      margin-top: 20px;
    }

    .quote-item {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      background-color: rgb(122 122 122 / 10%);
      padding: 40px;
      width: 414px;
      height: 320px;
      margin-top: -20px;

      @media (max-width: @screen-sm) {
        width: 100%;
      }

      .quote {
        font-size: 18px;
        color: #2f2b46;
        text-decoration: none solid rgb(47, 43, 70);
        line-height: 28px;

        &.with-margin {
          margin-bottom: 30px;
        }
      }
    }

    .author-container {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      align-items: center;
    }

    .author {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: flex-start;
    }

    .image {
      width: 52px;
      height: 52px;
      border-radius: 26px;
      fill: #d8d8d8;
      stroke-width: 2;
      margin-right: 10px;
    }

    .name {
      font-weight: 500;
      font-size: 16px;
      color: rgb(47, 43, 70);
      line-height: 24px;
      opacity: 0.75;
    }

    .position {
      font-size: 16px;
      color: #6b7280;
      text-decoration: none solid rgb(107, 114, 128);
      line-height: 24px;
    }
  }
}

.form-logo {
  width: 200px;
  height: 51px;
  margin-bottom: 20px;
}

.form {
  padding: 20px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media (max-width: @screen-sm) {
    width: 100%;
  }

  &.settings {
    height: auto;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;

    .form-group {
      width: 500px;

      @media (max-width: @screen-sm) {
        width: 100%;
      }
    }

    &.wider {
      .form-group {
        width: 700px;

        @media (max-width: @screen-sm) {
          width: 100%;
        }
      }
    }

    .form-title {
      font-size: 25px;
    }
  }



  .form-title {
    font-size: 34px;
    color: #2d3e4a;
    text-decoration: none solid rgb(45, 62, 74);
    font-weight: 700;

    @media (max-width: @screen-sm) {
      text-align: center;
      width: 100%;
    }
  }

  .form-subtitle {
    font-size: 18px;
    color: #2f2b46;
    text-decoration: none solid rgb(47, 43, 70);
    font-weight: 500;
    text-align: center;
  }

  .form-group {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 450px;
    margin: 10px 0;

    @media (max-width: @screen-sm) {
      width: 100%;
    }

    label {
      font-size: 14px;
      color: #344054;
      z-index: 10;
      margin-bottom: 10px;
      font-weight: 400;
    }

    input {
      width: 100%;
      height: 40px;
      padding: 5px 14px;
      font-size: 14px;
      color: #666666;
      text-decoration: none solid rgb(102, 102, 102);
      border-radius: 8px;
      border: 1px solid #bdc5cf;
      background: #fff;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

      &.ng-invalid.ng-dirty {
        border: 1px solid #f80000;
      }

      &:focus-visible {
        outline: none;
      }
    }
  }

  .error-text {
    color: #f80000;
    margin-left: 5px;
    margin-top: 5px;
  }

  .submit-button {
    text-align: center;
    cursor: pointer;
    opacity: 1;
    border: none;
    margin-top: 10px;
    height: 40px;
    border-radius: 6px;
    background-color: #177af0;
    box-shadow:
      0px 1px 2px rgba(0, 0, 0, 0.06),
      0px 1px 3px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #ffffff;
    text-decoration: none solid rgb(255, 255, 255);
    line-height: 24px;
    font-weight: 600;
    padding: 0 30px;

    @media (max-width: @screen-sm) {
      width: 100%;
    }

    &.loading {
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.5;
    }

    &.invalid {
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
    }
  }

  .bottom-text {
    font-size: 18px;
    color: #3d4651;
    font-weight: 400;
    width: 100%;
    text-align: center;

    .link {
      color: #3d4651;
      font-weight: 700;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .bottom-text-smaller {
    font-size: 14px;
    color: #3d4651;
    font-weight: 300;

    .link {
      color: #3d4651;
      font-weight: 300;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-ExtraBold.ttf');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}
//@font-face {
//  font-family: 'Inter';
//  src: url('./assets/fonts/Inter-SemiBold.ttf');
//  font-weight: 600;
//  font-style: normal;
//}
@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Medium.ttf');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Regular.ttf');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Light.ttf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-ExtraLight.ttf');
  font-weight: 300;
  font-style: normal;
}

.close {
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #ffffff;
  background-color: transparent;
  border: none;
}

.page-title {
  font-size: 32px;
  color: #2d3e4a;
  text-decoration: none solid rgb(45, 62, 74);
  text-align: center;
  margin-bottom: 30px;
}

.alert-info {
  word-wrap: break-word !important;
  border-right: none;
  border-top: none;
  border-bottom: none;
  border-left: 2px solid;
  padding: 1em;
  margin-bottom: 20px;
  border-radius: 4px;
  color: #066783;
  background-color: #d7f5fd;
  border-color: #066783;
  width: 700px;
  text-align: left;
}

.df-dialog {
  border-radius: 12px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 24px 24px 50px;
  width: 500px;

  .dialog-close {
    top: 20px;
    position: absolute;
    right: 20px;
    opacity: 0.6;
    cursor: pointer;
  }

  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    text-align: center;
    color: #2d3e4a;
    margin-top: 0;

    .link {
      color: #187af0;
      cursor: pointer;
    }
  }

  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    color: #2d3e4a;
    margin-top: 10px;
    width: 370px;

    strong {
      font-weight: 600;
    }
  }

  .image {
    height: 50px;
  }

  .create-instance-button {
    margin-top: 20px;
    width: 220px;
    height: 45px;
    border: none;
    background: linear-gradient(264.29deg, #177af0 0%, #4a98f4 100%);
    border-radius: 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 600;
    cursor: pointer;
  }

  .instances-list {
    padding: 0;
    list-style: none;
    width: 100%;
    max-height: 50%;
    margin: 20px 0;
    overflow: auto;

    .instance-item {
      cursor: pointer;
      width: 100%;
      font-size: 18px;
      height: 40px;
      display: flex;
      align-items: center;
      padding: 10px;
      background: #eeeeee;
      border-radius: 10px;
      position: relative;
      margin: 5px 0;

      .first-letter {
        color: #ffffff;
        border-radius: 100%;
        background-color: #177af0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
        font-size: 14px;
        font-weight: 600;
        width: 25px;
        height: 25px;
      }

      .go-text {
        display: none;
        color: #177af0;
        font-weight: 600;
        position: absolute;
        right: 20px;
      }

      &:hover {
        background: #dddddd;

        .go-text {
          display: inline-block;
        }
      }
    }
  }

  .error-message {
    width: 100%;
    background-color: #ffeced;
    color: #f87067;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    padding: 5px 10px;
    font-weight: 600;
  }
}

.mat-mdc-dialog-container {
  border-radius: 10px !important;
  padding: 0 !important;
}

.mat-mdc-snack-bar-container {
  border: none;
  font-size: 14px;
  max-width: 90vw !important;
  min-height: 0 !important;

  .mdc-snackbar__surface {
    border-left: 2px solid;
  }

  &.no-shadow {
    box-shadow: none;
  }

  &.success {
    .mdc-snackbar__surface {
      .message,
      .fa,
      .alert-heading {
        color: #0e7b42;
      }
      border-color: #0e7b42;
      background-color: #c3f8dc;
    }
  }

  &.info {
    .mdc-snackbar__surface {
      .message,
      .fa,
      .alert-heading {
        color: #066783;
      }

      border-color: #066783;
      background-color: #d7f5fd;
    }
  }

  &.warning {
    .mdc-snackbar__surface {
      .message,
      .fa,
      .alert-heading {
        color: #d36500;
      }
      border-color: #d36500;
      background-color: #ffe8d4;
    }
  }

  &.danger {
    .mdc-snackbar__surface {
      .message,
      .fa,
      .alert-heading {
        color: #de2c1e;
      }

      border-color: #de2c1e;
      background-color: #fceceb;
    }
  }

  .notify-template {
    white-space: nowrap;

    .message-container {
      margin-right: 30px;
    }

    .fa-stack {
      margin-right: 10px;
    }
  }

  .message {
    margin: 0;
  }
}

.mat-mdc-dialog-container {
  padding: 0 !important;
  position: relative;
  overflow: hidden !important;

  .dialog-close {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 7px;

    &:before {
      background: transparent;
      border-radius: 3px;
      color: #bbb;
      content: '\00D7';
      font-size: 26px;
      font-weight: 400;
      height: 30px;
      line-height: 26px;
      position: absolute;
      right: 3px;
      text-align: center;
      top: 3px;
      width: 30px;
    }

    &:hover {
      &:before {
        color: #777;
      }
    }
  }
}

.footer {
  z-index: 1000;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  margin-top: -40px;

  @media (max-width: @screen-sm) {
    position: inherit;
    margin-top: 50px;
  }

  .footer-text {
    font-size: 20px;
    color: #2d3e4a;
    text-decoration: none solid rgb(45, 62, 74);
    line-height: 30px;
    text-align: center;

    a {
      color: #2d3e4a;
    }

    .separator {
      margin: 0 20px;
    }
  }
}
